"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPEConfigType = void 0;
var BPEConfigType;
(function (BPEConfigType) {
    BPEConfigType["REPLIES"] = "replies";
    BPEConfigType["COMMENT_BUTTON"] = "comment";
    BPEConfigType["FOLLOW_BUTTON"] = "follow";
    BPEConfigType["REPLY_BUTTON"] = "reply";
    BPEConfigType["QRCODE_USER"] = "qr-user";
    BPEConfigType["QRCODE_CONTENT"] = "qr-content";
    BPEConfigType["QRCODE_EXTERNAL"] = "qr-url";
    BPEConfigType["VIDEO"] = "video";
    BPEConfigType["USER_GRID"] = "user-grid";
    BPEConfigType["REPLIES_EXTERNAL"] = "ext";
    BPEConfigType["DOMAIN"] = "domain";
    BPEConfigType["QUERY"] = "query";
    BPEConfigType["VIDEO_PAGED"] = "video-paged";
    BPEConfigType["HASHTAG"] = "hashtag";
})(BPEConfigType = exports.BPEConfigType || (exports.BPEConfigType = {}));
