"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MessageObservable = /** @class */ (function () {
    function MessageObservable() {
    }
    /**
     * Añade un observador
     * @param observer
     */
    MessageObservable.subscribe = function (observer) {
        this.observers.push(observer);
    };
    /**
     * Elimina el observador
     * @param observer
     */
    MessageObservable.unsubscribe = function (observer) {
        this.observers = this.observers.filter(function (ob) { return ob != observer; });
    };
    /**
     * Maneja el evento
     * @param notificationEvent
     */
    MessageObservable.handle = function (iframeWidget, notificationEvent) {
        //Avisamos a los observadores de este tipo
        this.observers.forEach(function (observer) {
            if (notificationEvent.eventType == observer.getHandledType()) {
                observer.handle(iframeWidget, notificationEvent);
            }
        });
    };
    /**
     * Observadores suscritos a mensajes
     */
    MessageObservable.observers = [];
    return MessageObservable;
}());
exports.default = MessageObservable;
