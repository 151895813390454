"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ModalManager = /** @class */ (function () {
    function ModalManager() {
        this._modalFrameClassCss = "bp-modal-frame";
        this.handParentWindowClick = this.handParentWindowClick.bind(this);
    }
    /**
     * Singleton instance
     */
    ModalManager.instance = function () {
        if (!ModalManager._instance)
            ModalManager._instance = new ModalManager();
        return ModalManager._instance;
    };
    ModalManager.prototype.create = function () {
        if (!this._modal) {
            this._modal = document.createElement("div");
            this._modal.classList.add(this._modalFrameClassCss);
            this._modal.appendChild(this.createCloseButton());
            this._modal.appendChild(this.createContainer());
        }
        return this._modal;
    };
    /**
     * Devuelve la instancia HTMLDivElement modal
     */
    ModalManager.prototype.container = function () {
        this.create();
        return this._container;
    };
    /**
     * Adds content to modal
     * @param content
     */
    ModalManager.prototype.setContent = function (content) {
        this.container();
        this.clear();
        this._container.append(content);
    };
    /**
     * Renders the modal on parent window
     */
    ModalManager.prototype.render = function () {
        if (this._modal) {
            this.remove();
            window.parent.document.body.append(this._modal);
            this.registerParentWindowClick();
        }
    };
    ModalManager.prototype.handParentWindowClick = function (e) {
        //Si se ha hecho click sobre una capa que NO contenga la clase definida en 'this._modalFrameClassCss' y el click es de un usuario, se cierra la modal
        if (document.getElementsByClassName(this._modalFrameClassCss).length > 0 &&
            false == document.getElementsByClassName(this._modalFrameClassCss)[0].contains(e.target) &&
            e.isTrusted) {
            this.remove();
        }
    };
    ModalManager.prototype.registerParentWindowClick = function () {
        window.removeEventListener("click", this.handParentWindowClick);
        window.addEventListener("click", this.handParentWindowClick);
    };
    ModalManager.prototype.createCloseButton = function () {
        var closeButton = document.createElement("button");
        closeButton.value = "bp-close-modal";
        closeButton.classList.add("bp-close-modal");
        closeButton.classList.add("bp-button");
        closeButton.textContent = "X";
        closeButton.onclick = this.remove.bind(this);
        return closeButton;
    };
    ModalManager.prototype.createContainer = function () {
        this._container = document.createElement("div");
        this._container.classList.add("bp-modal-container");
        return this._container;
    };
    /**
     * Removes the modal
     */
    ModalManager.prototype.remove = function () {
        if (this._modal)
            this._modal.remove();
    };
    /**
     * Destroy the modal
     */
    ModalManager.prototype.destroy = function () {
        this._modal = null;
        this._container = null;
    };
    /**Clean the content */
    ModalManager.prototype.clear = function () {
        if (this._container)
            this._container.innerHTML = "";
    };
    return ModalManager;
}());
exports.default = ModalManager;
