"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPEConfigValidator = void 0;
var BPEConfigType_1 = require("./BPEConfigType");
var BPVideoPagedType_1 = require("./BPVideoPagedType");
var BPEConfigValidator = /** @class */ (function () {
    function BPEConfigValidator() {
    }
    /**
     * Validate attributes from BPElementConfig
     * @param config
     */
    BPEConfigValidator.validate = function (config) {
        switch (config.type) {
            case BPEConfigType_1.BPEConfigType.REPLIES: {
                return this.validateAttributes(config, this.requiredRepliesAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.FOLLOW_BUTTON: {
                return this.validateAttributes(config, this.requiredFollowAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.COMMENT_BUTTON: {
                return this.validateAttributes(config, this.requiredCommentAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.REPLY_BUTTON: {
                return this.validateAttributes(config, this.requiredReplyAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.QRCODE_USER: {
                return this.validateAttributes(config, this.requiredQRCodeUserAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.QRCODE_CONTENT: {
                return this.validateAttributes(config, this.requiredQRCodeContentAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.QRCODE_EXTERNAL: {
                return this.validateAttributes(config, this.requiredQRCodeUrlAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.VIDEO: {
                return this.validateAttributes(config, this.requiredVideoAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.USER_GRID: {
                return this.validateAttributes(config, this.requiredUserGridAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.REPLIES_EXTERNAL: {
                return this.validateAttributes(config, this.requiredExternalRepliesAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.DOMAIN: {
                return this.validateAttributes(config, this.requiredDomainAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.HASHTAG: {
                return this.validateAttributes(config, this.requiredHashtagAttributes);
                break;
            }
            case BPEConfigType_1.BPEConfigType.VIDEO_PAGED: {
                switch (config.attributes.get("filter-type")) {
                    case BPVideoPagedType_1.BPVideoPagedType.DOMAIN:
                    case BPVideoPagedType_1.BPVideoPagedType.REPLIES_EXTERNAL:
                    case BPVideoPagedType_1.BPVideoPagedType.USER:
                    case BPVideoPagedType_1.BPVideoPagedType.REPLIES:
                    case BPVideoPagedType_1.BPVideoPagedType.QUERY:
                    case BPVideoPagedType_1.BPVideoPagedType.HASHTAG:
                        return this.validateAttributes(config, this.requiredVideoPagedAttributes);
                        break;
                    default:
                        return false;
                }
                break;
            }
            case BPEConfigType_1.BPEConfigType.QUERY: {
                return this.validateAttributes(config, this.requiredExternalQueryAttributes);
                break;
            }
            default:
                return false;
        }
    };
    /**
     * Valida que el tag de replies tenga los attributos necesarios para funcionar
     * @param config
     */
    BPEConfigValidator.validateAttributes = function (config, requiredAttributes) {
        var valid = true;
        requiredAttributes.forEach(function (attr) {
            if (!config.attributes.get(attr.toLowerCase())) {
                valid = false;
                console.log("BPSDK::" + config.type + "::(Error) Required '" + attr.toLowerCase() + "' data attribute is missing");
                return false;
            }
        });
        return valid;
    };
    /**
     * Required attributes for each type
     */
    BPEConfigValidator.requiredRepliesAttributes = ["contentid"];
    BPEConfigValidator.requiredFollowAttributes = ["userid"];
    BPEConfigValidator.requiredCommentAttributes = ["contentid"];
    BPEConfigValidator.requiredReplyAttributes = ["contentid"];
    BPEConfigValidator.requiredQRCodeUserAttributes = ["userid"];
    BPEConfigValidator.requiredQRCodeContentAttributes = ["contentid"];
    BPEConfigValidator.requiredQRCodeUrlAttributes = ["contentid"];
    BPEConfigValidator.requiredVideoAttributes = ["contentid"];
    BPEConfigValidator.requiredUserGridAttributes = ["userid"];
    BPEConfigValidator.requiredExternalRepliesAttributes = ["contentid"];
    BPEConfigValidator.requiredDomainAttributes = [];
    BPEConfigValidator.requiredExternalQueryAttributes = ["query"];
    BPEConfigValidator.requiredHashtagAttributes = ["hashtag"];
    BPEConfigValidator.requiredVideoPagedAttributes = ["filter-type", "contentid", "numberposts", "filter"];
    return BPEConfigValidator;
}());
exports.BPEConfigValidator = BPEConfigValidator;
exports.default = BPEConfigValidator;
