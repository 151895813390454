"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NotificationEventType_1 = require("../../NotificationEvent/NotificationEventType");
/**
 *
 */
var ResizeObserver = /** @class */ (function () {
    function ResizeObserver() {
    }
    /**
     * El tipo de notificacion que va a manejar
     */
    ResizeObserver.prototype.getHandledType = function () {
        return NotificationEventType_1.NotificationEventType.RESIZE;
    };
    /**
     * Maneja la notificacion
     * @param iframeWidget
     * @param notificationEvent
     */
    ResizeObserver.prototype.handle = function (iframeWidget, notificationEvent) {
        if (notificationEvent.eventType == this.getHandledType())
            iframeWidget.getIframe().height = notificationEvent.data.height;
    };
    return ResizeObserver;
}());
exports.default = ResizeObserver;
