"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenGraphProvider = void 0;
var OpenGraphProvider = /** @class */ (function () {
    function OpenGraphProvider() {
    }
    /**
     *
     * @param property
     */
    OpenGraphProvider.prototype.get = function (property) {
        return this.readByProperty(property);
    };
    /**
     * Read meta og by property
     * @param property
     */
    OpenGraphProvider.prototype.readByProperty = function (property) {
        var metaNode = document.querySelectorAll('meta[property="og:' + property + '"]')[0];
        if (metaNode && metaNode.content)
            return encodeURIComponent(metaNode.content);
        return encodeURIComponent(document[property]) || "";
    };
    return OpenGraphProvider;
}());
exports.OpenGraphProvider = OpenGraphProvider;
exports.default = OpenGraphProvider;
