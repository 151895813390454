"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppSettings = void 0;
var AppSettings = /** @class */ (function () {
    function AppSettings() {
    }
    AppSettings.IFRAME_BASE_URL = function () {
        if (process.env.REACT_APP_IFRAME_BASE_URL) {
            return process.env.REACT_APP_IFRAME_BASE_URL;
        }
    };
    AppSettings.IFRAME_MESSAGE_VALID_ORIGIN_DOMAIN = function () {
        if (process.env.REACT_APP_IFRAME_MESSAGE_VALID_ORIGIN_DOMAIN) {
            return process.env.REACT_APP_IFRAME_MESSAGE_VALID_ORIGIN_DOMAIN.split(",");
        }
    };
    AppSettings.CORE_AUTH_ENDPOINT = function () {
        if (process.env.REACT_APP_CORE_AUTH_ENDPOINT) {
            return process.env.REACT_APP_CORE_AUTH_ENDPOINT;
        }
    };
    AppSettings.LOGIN_URL = function () {
        if (process.env.REACT_APP_LOGIN_URL) {
            return process.env.REACT_APP_LOGIN_URL;
        }
    };
    AppSettings.BP_SERVICE_URL = function () {
        if (process.env.REACT_APP_BP_SERVICE_URL) {
            return process.env.REACT_APP_BP_SERVICE_URL;
        }
    };
    AppSettings.BP_SERVICE_URL_TRENDING = function () {
        if (process.env.REACT_APP_BP_SERVICE_URL_TRENDING) {
            return process.env.REACT_APP_BP_SERVICE_URL_TRENDING;
        }
    };
    return AppSettings;
}());
exports.AppSettings = AppSettings;
exports.default = AppSettings;
