"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDispatcher = void 0;
var MessageDispatcher = /** @class */ (function () {
    function MessageDispatcher() {
    }
    /**
     * Invoke postMessage on param window
     * @param window
     * @param eventNotification
     */
    MessageDispatcher.send = function (window, eventNotification) {
        window.postMessage(eventNotification, "*");
    };
    return MessageDispatcher;
}());
exports.MessageDispatcher = MessageDispatcher;
exports.default = MessageDispatcher;
