"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPElementConfig = void 0;
var guid_typescript_1 = require("guid-typescript");
var BPElementConfig = /** @class */ (function () {
    function BPElementConfig() {
        this.id = guid_typescript_1.Guid.create().toString();
        this.attributes = new Map();
    }
    return BPElementConfig;
}());
exports.BPElementConfig = BPElementConfig;
exports.default = BPElementConfig;
