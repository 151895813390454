"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationEvent = void 0;
var NotificationEvent = /** @class */ (function () {
    function NotificationEvent(eventType, targetId, data) {
        this.eventType = eventType;
        this.targetId = targetId;
        this.data = data;
    }
    return NotificationEvent;
}());
exports.NotificationEvent = NotificationEvent;
exports.default = NotificationEvent;
