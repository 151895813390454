"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RemoteUIRequest = /** @class */ (function () {
    function RemoteUIRequest(frameOrigin, notificationEvent, type) {
        this.frameOrigin = frameOrigin;
        this.notificationEvent = notificationEvent;
        this.type = type;
    }
    /**
     *
     */
    RemoteUIRequest.prototype.getFrameOrigin = function () {
        return this.frameOrigin;
    };
    /**
     *
     */
    RemoteUIRequest.prototype.getNotificationEvent = function () {
        return this.notificationEvent;
    };
    /**
     *
     */
    RemoteUIRequest.prototype.getRequestType = function () {
        return this.type;
    };
    return RemoteUIRequest;
}());
exports.default = RemoteUIRequest;
