"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NotificationEventType_1 = require("../../NotificationEvent/NotificationEventType");
/**
 *
 */
var RedirectObserver = /** @class */ (function () {
    function RedirectObserver() {
    }
    /**
     * El tipo de notificacion que va a manejar
     */
    RedirectObserver.prototype.getHandledType = function () {
        return NotificationEventType_1.NotificationEventType.REDIRECT;
    };
    /**
     * Maneja la notificacion
     * @param iframeWidget
     * @param notificationEvent
     */
    RedirectObserver.prototype.handle = function (iframeWidget, notificationEvent) {
        if (notificationEvent.eventType == this.getHandledType())
            document.location.href = notificationEvent.data.url;
    };
    return RedirectObserver;
}());
exports.default = RedirectObserver;
