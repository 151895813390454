"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RemoteUIRequestType_1 = require("./requests/RemoteUIRequestType");
var RemoteUIManager = /** @class */ (function () {
    /**
     * Conrtuctor con instancias de handlers
     * @param videoHandler
     */
    function RemoteUIManager(videoHandler) {
        this.videoHandler = videoHandler;
    }
    /**
     * Maneja la peticion de interaccion con UI remoto
     * @param request
     */
    RemoteUIManager.prototype.manage = function (request) {
        switch (request.getRequestType()) {
            case RemoteUIRequestType_1.RemoteUIRequestType.VIDEO:
                this.videoHandler.handle(request);
                break;
        }
    };
    return RemoteUIManager;
}());
exports.default = RemoteUIManager;
