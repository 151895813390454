"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPVideoPagedType = void 0;
var BPVideoPagedType;
(function (BPVideoPagedType) {
    BPVideoPagedType["REPLIES_EXTERNAL"] = "ext";
    BPVideoPagedType["USER"] = "user-grid";
    BPVideoPagedType["DOMAIN"] = "domain";
    BPVideoPagedType["REPLIES"] = "replies";
    BPVideoPagedType["QUERY"] = "query";
    BPVideoPagedType["HASHTAG"] = "hashtag";
})(BPVideoPagedType = exports.BPVideoPagedType || (exports.BPVideoPagedType = {}));
