"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlParser = void 0;
var UrlParser = /** @class */ (function () {
    function UrlParser(rawURL) {
        try {
            this.base = new URL(rawURL);
            this.params = new URLSearchParams(this.base.search.slice(1) + this.base.hash.slice(1));
        }
        catch (e) {
            this.base = new URL("");
            this.params = new URLSearchParams("");
        }
    }
    UrlParser.prototype.getRawURL = function () {
        return this.base;
    };
    UrlParser.prototype.getHost = function () {
        return this.base.hostname;
    };
    UrlParser.prototype.getParam = function (name) {
        return this.params.get(name);
    };
    UrlParser.prototype.getParams = function () {
        return this.params;
    };
    return UrlParser;
}());
exports.UrlParser = UrlParser;
exports.default = UrlParser;
