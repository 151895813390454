"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Encoding = void 0;
var Encoding = /** @class */ (function () {
    function Encoding() {
    }
    Encoding.utf8_encode = function (string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c >= 2048) {
                utftext += " ";
            }
            else {
                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if (c > 127 && c < 2048) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
            }
        }
        return utftext;
    };
    /**
     * Formatea los ids externos, urls,etc para ser enviados el deeplinks o por url
     * @param string
     * @returns
     */
    Encoding.formatExternalData = function (string) {
        return decodeURIComponent(encodeURIComponent(Encoding.utf8_encode(string.normalize())));
    };
    return Encoding;
}());
exports.Encoding = Encoding;
exports.default = Encoding;
